import { openDefaultEditor } from 'libraries/pintura'
import { images } from './pintura_config.js'
const {
  iconImg,
  serviceBannerImg,
  servicePromotedImg,
  homeScreenPromotionBannerImg,
  homeScreenPromotionBannerImgAr,
  homeScreenPromotionIcon,
  shopCategoryImg,
  announcementImg,
  promotionTagIcon,
  tagBackground,
  tagBanner,
  tagIcon
} = images

function pintura () {
  $('.pintura').on('change', (e) => {
    const fileInput = e.target
    const previewImg = setPreviewImage(fileInput)
    if (!validateFileType(e.target, previewImg)) {
      fileInput.value = ''
      return false
    }

    if (!fileInput.files.length) return
    const editor = openDefaultEditor({
      src: fileInput.files[0],
      enableZoom: true,
      enableZoomControls: true,
      zoomLevel: null,
      zoomPresetOptions: [0.25, 0.5, 1, 1.25, 1.5, 2, 3, 4, 6, 8, 16],
      zoomAdjustStep: 0.25,
      zoomAdjustFactor: 0.1,
      zoomMaskOpacity: 0.85,
      enableTransparencyGrid: true,
      imageCropAspectRatio: previewImg.ratio,
      imageState: {
        cropMinSize: { width: previewImg.size.minWidth, height: previewImg.size.minHeight },
        cropMaxSize: { width: previewImg.size.maxWidth, height: previewImg.size.maxHeight }
      },
      cropEnableInfoIndicator: true
    })

    editor.on('process', ({ dest }) => {
      // create a files list
      const dataTransfer = new DataTransfer()
      dataTransfer.items.add(dest)
      if (!validateImgSize(previewImg, e.target, dataTransfer.files[0])) {
        e.target.value = ''
        return
      }
      // update the file input
      fileInput.files = dataTransfer.files
      const _URL = window.URL || window.webkitURL
      document.getElementById(previewImg.previewId).src = _URL.createObjectURL(dest)
      if (previewImg.previewId === 'image') {
        $('.remove-banner-image').removeClass('hide')
        $('.remove_vImage_hidden').val('false')
      }
    })
    editor.on('close', () => {
      e.target.value = ''
    })
  })

  function validateImgSize (previewImg, input, file) {
    clearError(input, previewImg)
    const sizeKB = Math.round(file.size / 1024)
    const minSize = previewImg.minSizeKB
    const maxSize = previewImg.maxSizeKB
    if (sizeKB < minSize || sizeKB > maxSize) {
      $(input)
        .parent()
        .find(previewImg.warningClassName)
        .html(`The edited attachment size is ${sizeKB}KB
          and it should be minimum of ${minSize}KB
          and maximum of ${maxSize}KB`)
      return false
    }

    return true
  }

  function validateFileType (input, previewImg) {
    clearError(input, previewImg)
    const allowedExtension = ['jpg', 'jpeg', 'gif', 'png']
    const wrongInputError = 'Only image file with extension: .jpg, .jpeg, .gif or .png is allowed'
    const fileExtension = input.value.split('.').pop().toLowerCase()
    if ($.inArray(fileExtension, allowedExtension) === -1) {
      $(input).parent().find(previewImg.warningClassName).html(wrongInputError)
      return false
    };
    return true
  }

  function clearError (input, previewImg) {
    $(input).parent().find(previewImg.warningClassName).html('')
  }

  function setPreviewImage (fileInput) {
    let previewImg = {}

    switch (fileInput.id) {
      case 'shop_vShopPic':
        previewImg = { ...iconImg }
        break
      case 'shop_ppd_detail_vImage':
        previewImg = { ...serviceBannerImg }
        break
      case 'shop_ppd_detail_promoted_image':
        previewImg = { ...servicePromotedImg }
        break
      case 'home_screen_promotion_banner':
        previewImg = { ...homeScreenPromotionBannerImg }
        break
      case 'home_screen_promotion_banner_ar':
        previewImg = { ...homeScreenPromotionBannerImgAr }
        break
      case 'home_screen_promotion_icon':
        previewImg = { ...homeScreenPromotionIcon }
        break
      case 'shop_category_image':
        previewImg = { ...shopCategoryImg }
        break
      case 'announcement_image':
        previewImg = { ...announcementImg }
        break
      case 'app_display_primary_section_content_component_promotion_tag_icon':
        previewImg = { ...promotionTagIcon }
        break
      case 'tag_background':
        previewImg = { ...tagBackground }
        break
      case 'tag_banner':
        previewImg = { ...tagBanner }
        break
      case 'tag_icon':
        previewImg = { ...tagIcon }
        break
    }
    return previewImg
  }
}
export default pintura
